/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
@import "./theme/newColors.scss";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.cursor {
  cursor: pointer;
}

.content {
  padding-top: 20px;
}

.textarea-notes {
  position: relative !important;
  height: 100%;
  & div {
    height: 100%;
    & textarea {
      height: 100%;
    }
  }
}

.iconAvatar {
  font-size: 40px;
}

.iconAvatar:hover {
  color: rgb(56, 222, 252);
}

.icon {
  font-size: 25px;
}

.icon:hover {
  color: rgb(56, 222, 252);
}

.texto-light {
  color: black !important;
}

.texto-dark {
  color: white !important;
}
