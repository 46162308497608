/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
.ion-color-pinkLight {
  --ion-color-base: var(--ion-color-pinkLight);
  --ion-color-base-rgb: var(--ion-color-pinkLight-rgb);
  --ion-color-contrast: var(--ion-color-pinkLight-contrast);
  --ion-color-contrast-rgb: var(--ion-color-pinkLight-contrast-rgb);
  --ion-color-shade: var(--ion-color-pinkLight-shade);
  --ion-color-tint: var(--ion-color-pinkLight-tint);
}

.ion-color-pink {
  --ion-color-base: var(--ion-color-pink);
  --ion-color-base-rgb: var(--ion-color-pink-rgb);
  --ion-color-contrast: var(--ion-color-pink-contrast);
  --ion-color-contrast-rgb: var(--ion-color-pink-contrast-rgb);
  --ion-color-shade: var(--ion-color-pink-shade);
  --ion-color-tint: var(--ion-color-pink-tint);
}

.ion-color-orangeLight {
  --ion-color-base: var(--ion-color-orangeLight);
  --ion-color-base-rgb: var(--ion-color-orangeLight-rgb);
  --ion-color-contrast: var(--ion-color-orangeLight-contrast);
  --ion-color-contrast-rgb: var(--ion-color-orangeLight-contrast-rgb);
  --ion-color-shade: var(--ion-color-orangeLight-shade);
  --ion-color-tint: var(--ion-color-orangeLight-tint);
}

.ion-color-orange {
  --ion-color-base: var(--ion-color-orange);
  --ion-color-base-rgb: var(--ion-color-orange-rgb);
  --ion-color-contrast: var(--ion-color-orange-contrast);
  --ion-color-contrast-rgb: var(--ion-color-orange-contrast-rgb);
  --ion-color-shade: var(--ion-color-orange-shade);
  --ion-color-tint: var(--ion-color-orange-tint);
}

.ion-color-greenLight {
  --ion-color-base: var(--ion-color-greenLight);
  --ion-color-base-rgb: var(--ion-color-greenLight-rgb);
  --ion-color-contrast: var(--ion-color-greenLight-contrast);
  --ion-color-contrast-rgb: var(--ion-color-greenLight-contrast-rgb);
  --ion-color-shade: var(--ion-color-greenLight-shade);
  --ion-color-tint: var(--ion-color-greenLight-tint);
}

.ion-color-green {
  --ion-color-base: var(--ion-color-green);
  --ion-color-base-rgb: var(--ion-color-green-rgb);
  --ion-color-contrast: var(--ion-color-green-contrast);
  --ion-color-contrast-rgb: var(--ion-color-green-contrast-rgb);
  --ion-color-shade: var(--ion-color-green-shade);
  --ion-color-tint: var(--ion-color-green-tint);
}

.ion-color-blueLight {
  --ion-color-base: var(--ion-color-blueLight);
  --ion-color-base-rgb: var(--ion-color-blueLight-rgb);
  --ion-color-contrast: var(--ion-color-blueLight-contrast);
  --ion-color-contrast-rgb: var(--ion-color-blueLight-contrast-rgb);
  --ion-color-shade: var(--ion-color-blueLight-shade);
  --ion-color-tint: var(--ion-color-blueLight-tint);
}

.ion-color-blue {
  --ion-color-base: var(--ion-color-blue);
  --ion-color-base-rgb: var(--ion-color-blue-rgb);
  --ion-color-contrast: var(--ion-color-blue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-blue-contrast-rgb);
  --ion-color-shade: var(--ion-color-blue-shade);
  --ion-color-tint: var(--ion-color-blue-tint);
}

.ion-color-blueDark {
  --ion-color-base: var(--ion-color-blueDark);
  --ion-color-base-rgb: var(--ion-color-blueDark-rgb);
  --ion-color-contrast: var(--ion-color-blueDark-contrast);
  --ion-color-contrast-rgb: var(--ion-color-blueDark-contrast-rgb);
  --ion-color-shade: var(--ion-color-blueDark-shade);
  --ion-color-tint: var(--ion-color-blueDark-tint);
}

.ion-color-violetLight {
  --ion-color-base: var(--ion-color-violetLight);
  --ion-color-base-rgb: var(--ion-color-violetLight-rgb);
  --ion-color-contrast: var(--ion-color-violetLight-contrast);
  --ion-color-contrast-rgb: var(--ion-color-violetLight-contrast-rgb);
  --ion-color-shade: var(--ion-color-violetLight-shade);
  --ion-color-tint: var(--ion-color-violetLight-tint);
}

.ion-color-violet {
  --ion-color-base: var(--ion-color-violet);
  --ion-color-base-rgb: var(--ion-color-violet-rgb);
  --ion-color-contrast: var(--ion-color-violet-contrast);
  --ion-color-contrast-rgb: var(--ion-color-violet-contrast-rgb);
  --ion-color-shade: var(--ion-color-violet-shade);
  --ion-color-tint: var(--ion-color-violet-tint);
}

.ion-color-coffe {
  --ion-color-base: var(--ion-color-coffe);
  --ion-color-base-rgb: var(--ion-color-coffe-rgb);
  --ion-color-contrast: var(--ion-color-coffe-contrast);
  --ion-color-contrast-rgb: var(--ion-color-coffe-contrast-rgb);
  --ion-color-shade: var(--ion-color-coffe-shade);
  --ion-color-tint: var(--ion-color-coffe-tint);
}

.ion-color-yellow {
  --ion-color-base: var(--ion-color-yellow);
  --ion-color-base-rgb: var(--ion-color-yellow-rgb);
  --ion-color-contrast: var(--ion-color-yellow-contrast);
  --ion-color-contrast-rgb: var(--ion-color-yellow-contrast-rgb);
  --ion-color-shade: var(--ion-color-yellow-shade);
  --ion-color-tint: var(--ion-color-yellow-tint);
}

.ion-color-coral {
  --ion-color-base: var(--ion-color-coral);
  --ion-color-base-rgb: var(--ion-color-coral-rgb);
  --ion-color-contrast: var(--ion-color-coral-contrast);
  --ion-color-contrast-rgb: var(--ion-color-coral-contrast-rgb);
  --ion-color-shade: var(--ion-color-coral-shade);
  --ion-color-tint: var(--ion-color-coral-tint);
}

.ion-color-indianRed {
  --ion-color-base: var(--ion-color-indianRed);
  --ion-color-base-rgb: var(--ion-color-indianRed-rgb);
  --ion-color-contrast: var(--ion-color-indianRed-contrast);
  --ion-color-contrast-rgb: var(--ion-color-indianRed-contrast-rgb);
  --ion-color-shade: var(--ion-color-indianRed-shade);
  --ion-color-tint: var(--ion-color-indianRed-tint);
}

/* Basic CSS for apps built with Ionic */
/* Optional CSS utils that can be commented out */
.cursor {
  cursor: pointer;
}

.content {
  padding-top: 20px;
}

.textarea-notes {
  position: relative !important;
  height: 100%;
}
.textarea-notes div {
  height: 100%;
}
.textarea-notes div textarea {
  height: 100%;
}

.iconAvatar {
  font-size: 40px;
}

.iconAvatar:hover {
  color: #38defc;
}

.icon {
  font-size: 25px;
}

.icon:hover {
  color: #38defc;
}

.texto-light {
  color: black !important;
}

.texto-dark {
  color: white !important;
}